<template>
  <div>
    <Operators></Operators>
    <MaterialAssortments></MaterialAssortments>
  </div>
</template>

<script>
import Operators from "./Operators.vue";
import MaterialAssortments from "./MaterialAssortments.vue";

export default {
  name: "Assortment",
  components: {
    Operators,
    MaterialAssortments
  }
};
</script>

<style></style>
