<template>
  <div>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>{{ $t("navbar.Operators") }}</v-card-title>

      <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="700px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.name"
                      v-bind:label="$t('brands.name')"
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="selected.description"
                      v-bind:label="$t('brands.description')"
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="d-flex justify-center"
                  >
                    <v-radio-group
                      v-model="selected.status"
                      row
                      required
                      :rules="requiredRules"
                    >
                      <v-radio label="Active" value="active"></v-radio>
                      <v-radio label="Inactive" value="inactive"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">

                    <b-alert show><b>Excepciones por provincia:</b> Añade aquí las provincias en las que <b>NO</b> opera.</b-alert>

                    <v-autocomplete
                        v-model="selected.geographyIds"
                        v-bind:label="$t('geography')"
                        v-bind:placeholder="$t('start_typing_to_search')"
                        item-text="label"
                        item-value="id"
                        :search-input.sync="search_geography"
                        :loading="isGeographySearching"
                        :items="filtered_geography"
                        @change="onChangeGeography"
                        hide-no-data
                        filled
                        multiple
                    >
                      <template v-slot:prepend-item>
                        <v-list-item
                            ripple
                            @click="toggleSelectAllOptions('geographyIds')"
                        >
                          <v-list-item-action>
                            <v-icon
                                :color="
                                selected.geographyIds.length > 0
                                  ? 'indigo darken-4'
                                  : ''
                              "
                            >{{ icon }}</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>

              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditDialog">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="onSaveClick(selected)">
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h6">{{
            $t("brands.operator_confirmdelete")
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">{{
              $t("cancel")
            }}</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="primary" dark class="mb-2" @click="onItemEdit">
              {{ $t("newitem") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              ref="tableRef"
              id="operators-table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="operators"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";

export default {
  name: "Operators",
  data: function() {
    return {
      filterable_col_list: ["name", "description", "status"],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterCriteria: {
        status: {},
        name: {},
        description: {},
      },
      showEmpty: false,

      totaloperators: 0,
      operators: [],

      /********** geography **********/
      search_geography: null,
      isGeographySearching: false,
      filtered_geography: [],

      valid: true,
      requiredRules: [(v) => !!v || "Required"],
      selected: null,
      editedIndex: -1,
      deleteItemId: -1,
      showEdit: false,
      dialogDelete: false,
    };
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    async search_geography(val) {
      let filterable = this.filtered_geography.filter(
          (item) => item.label === val
      );
      if (val !== null && val !== "" && filterable.length === 0) {
        this.isGeographySearching = true;
        let fetch_url = "pos/get_geographies?filter_name=" + val;
        const resp = await ApiService.get(fetch_url);
        this.filtered_geography = resp.data;
        this.isGeographySearching = false;
      }
    },
  },
  computed: {

    likesAllGeographies() {
      return (
          this.selected && (this.selected.geographyIds.length === this.filtered_geography.length)
      );
    },

    icon() {
      if (this.likesAllGeographies) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },

    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    columns() {
      return [
        {
          key: "id",
          title: "ID",
          field: "id",
          align: "left",
          width: "7%",
          sortBy: "ASC",
        },
        {
          key: "name",
          title: this.$t("salesforce.name"),
          field: "name",
          align: "left",
          sortBy: "",
          width: "24%",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["name"]}
                    apiUrl={`operators/getfilterlist`}
                    columnName={`name`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["name"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "name")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
        {
          key: "description",
          title: this.$t("brands.description"),
          field: "description",
          align: "left",
          sortBy: "",
          width: "36%",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["description"]}
                    apiUrl={`operators/getfilterlist`}
                    columnName={`description`}
                    isFullText={false}
                    filterCriteria={this.filterCriteria["description"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "description")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
        {
          key: "status",
          title: this.$t("salesforce.status"),
          field: "status",
          align: "left",
          sortBy: "",
          width: "15%",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["active"]}
                    label="Active"
                    value="active"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["inactive"]}
                    label="InActive"
                    value="inactive"
                  ></v-checkbox>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "status")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
        {
          key: "actions",
          title: this.$t("salesforce.Edit"),
          field: "actions",
          align: "center",
          width: "10%",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  on-click={() => this.onItemEdit(row)}
                >
                  mdi-pencil
                </v-icon>
              </div>
            );
          },
        },
      ];
    },
  },
  methods: {
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#operators-table"),
        name: "flow", // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },

    close() {
      this.loadingInstance.close();
    },

    onChangeGeography() {
      this.selected.geographiesChanged = true
    },

    createSelectedModel(item) {
      return {
        id: _.get(item, "id"),
        name: _.get(item, "name"),
        description: _.get(item, "description"),
        status:
          _.get(item, "status") !== undefined
            ? _.get(item, "status")
            : "active",
        geographyIds: _.get(item, "operator_geography_exceptions",[]).map(el=>el.geographyId)
      };
    },

    toggleSelectAllOptions(flag) {
      if (flag === "geographyIds") {
        if (this.likesAllGeographies) {
          this.selected.geographyIds = [];
        } else {
          this.selected.geographyIds = this.filtered_geography.map(
              (el) => el.id
          );
        }
      }
    },


    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },

    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },

    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },

    async onSaveClick(item) {
      if (this.$refs.form.validate()) {
        let body = item;
        if (body.id) {
          await ApiService.put(`operators/${body.id}`, body);
        } else {
          await ApiService.post(`operators`, body);
        }
        logInfo("Operadores actualizada");
        this.showEdit = false;
        this.getDataFromApi();
      }
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deleteItemId = -1;
      });
    },

    onItemRemove(item_id) {
      this.deleteItemId = item_id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      if (this.deleteItemId > 0) {
        this.closeDelete();
        ApiService.delete(`operators/${this.deleteItemId}`).then(() => {
          logInfo("Operadores eliminada");
          this.getDataFromApi();
        });
      }
    },

    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value,
            });
          }
        }
      }
      let filter_columns = document.querySelectorAll("#operators-table .filterable-column");
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map((filter_item) => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },

    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "operators";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const resp = await ApiService.get(fetch_url);
      this.operators = resp.data;
      this.filtered_geography = resp.geographies;
      if (this.operators.length > 0) {
        this.showEmpty = false;
      } else {
        this.showEmpty = true;
      }
      this.totaloperators = resp.total;
      this.close();
    },

    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },

    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },

    init() {
      this.getDataFromApi();
    },
  },
  async mounted() {
    this.init();
  },
};
</script>

<style></style>
